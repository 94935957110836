//*** import react lib
import { withGoogleMap, withScriptjs, GoogleMap, Marker, useLoadScript, Polyline, DirectionsRenderer, Polygon } from "@react-google-maps/api";
import * as geometry from 'spherical-geometry-js';
//import { computeArea } from 'spherical-geometry-js';
import { useMemo, useEffect, useState, useLayoutEffect, useCallback } from "react";
import "../../Assets/Styles/Map.css";
import imgicon from "../../Assets/Images/icon.JPG";
import Axios from "axios";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";
export function RobotLocation() {
    const { isLoaded } = useLoadScript({
        //*** google map API key
        //AIzaSyAiAdkcZ_8Bzj5FVkUKdi9xAfOD8fb6vrc // old key Umesh sir
        //AIzaSyC16Y-R8bhKWx57M63qEGelyGB3SRfxFIU// New Key Umesh Sir
        //AIzaSyDmmBMzFbZmGwc3FMR90OQNy8K3WFFK9eU" //Milind created new key
        //googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyAiAdkcZ_8Bzj5FVkUKdi9xAfOD8fb6vrc",
        googleMapsApiKey:process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyDmmBMzFbZmGwc3FMR90OQNy8K3WFFK9eU",
    });
    const [progressPath, setProgressPath] = useState([]);
    const [robotPath, setPath] = useState([]);
    const [plantArea, setPlantArea] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
   // const [directionsResponse, setDirectionsResponse] = useState(null);
    useEffect(() => {
        GetRobotsProgressPath();
        GetPlantArea();
    }, [])
    useEffect(() => {
        console.log(robotPath)
    }, [robotPath])
    useEffect(() => {
        //GetRobotsProgressPath();
        GetPlantArea();
    }, [])
    useEffect(() => {
        console.log(plantArea)
    }, [plantArea])
    // Javascript program for the haversine formula
    function haversine(lat1, lon1, lat2, lon2) {
        // distance between latitudes
        // and longitudes
        //alert("gffgfg");
        let dLat = (lat2 - lat1) * Math.PI / 180.0;
        let dLon = (lon2 - lon1) * Math.PI / 180.0;

        // convert to radiansa
        lat1 = (lat1) * Math.PI / 180.0;
        lat2 = (lat2) * Math.PI / 180.0;

        // apply formulae
        let a = Math.pow(Math.sin(dLat / 2), 2) +
            Math.pow(Math.sin(dLon / 2), 2) *
            Math.cos(lat1) *
            Math.cos(lat2);
        let radius_in_km = 6371.0710;
        let radius_in_meter = 3958.8;
        let c = 2 * Math.asin(Math.sqrt(a));
        //alert(rad * c);
        return radius_in_meter * c;
    }
   
    //*** Get Robot Progres Path
    const GetRobotsProgressPath = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        let response = await Axios("/PlantRobotsOperation?pid=" + plant_id + "&rt=multiple");
        //let response = await Axios("/PlantRobotsOperation?pid=" + plant_id + "&rt=multiple");
        setProgressPath(response.data);
        //GetPlantArea();
       // let tempProgressPath = progressPath;
       // tempProgressPath.map((pathData) => {
       //    //alert(pathData.source_lat + pathData.source_lon, pathData.destination_lat, pathData.destination_lon);
       //    var distance = haversine(pathData.source_lat, pathData.source_lon, pathData.destination_lat, pathData.destination_lon);
       //    //alert("Robot ID:" + pathData.robotID +" Distance:"+ distance +"");
       //});
    }
    GetRobotsProgressPath();
    const GetPlantArea = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        let response = await Axios("/PlantArea?pid=" + plant_id + "&uid=1");
        setPlantArea(response.data);
       // let tempPlantArea = plantArea;
       // tempPlantArea.map((pathData) => {
       //     alert(pathData.plantAreaLat_1 + pathData.plantAreaLon_1, pathData.plantAreaLat_2, pathData.plantAreaLon_2);
       //    //var distance = haversine(pathData.source_lat, pathData.source_lon, pathData.destination_lat, pathData.destination_lon);
       //    //alert("Robot ID:" + pathData.robotID +" Distance:"+ distance +"");
       //});
    }
   GetPlantArea();
    //let directionsService = new window.google.maps.DirectionsService();
    ////alert("nnnnn2"); //originRef.current.value,  //destiantionRef.current.value,
    //directionsService.route({
    //    origin: new window.google.maps.LatLng(18.698715, 73.919295),
    //    destination: new window.google.maps.LatLng(18.698749, 73.919377),
    //    travelMode: window.google.maps.TravelMode.DRIVING,
    //},
    //    (status, result) => {
    //        //alert("1");
    //        // alert(result);
    //        if (status === window.google.maps.DirectionsStatus.OK) {
    //            console.log(result);
    //            //alert(result);
    //            ////setProgressPath(result);
    //            ////this.setState({
    //            ////    directions: result
    //            ////});
    //        } else {
    //            console.error(`error fetching directions ${result}`);
    //        }
    //    });


    //*** Get robot icon
    const icon1 = {
        //url: "http://localhost:44463/#/src/Assets/Images/icon_new.jpg",
        url: "http://bhuhit.com/#/src/Assets/Images/icon_new.jpg",
        //"https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png",
    };
    //*** Get line symbol array
    var lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 4
    };
    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            //return;
        }
        setActiveMarker(marker);
    };
    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    //const [plantArea, setPlantArea] = useState([
    //    { lat: 18.698730, lng: 73.919318 },
    //    { lat: 18.698735, lng: 73.919177 },

    //    //{ lat: 18.699063, lng: 73.919419 },
    //    //{ lat: 18.699098, lng: 73.919206 },

    //    { lat: 18.699471, lng: 73.919279 },
    //    { lat: 18.699497, lng: 73.919386 }

    //]);
   const [plantOperationalArea, setPlantOperationalArea] = useState([
        { lat: 18.6999375, lng: 73.919279 },
        { lat: 18.699386, lng: 73.919362 },
        /*{ lat: 18.699098, lng: 73.919206 },*/
        /* { lat: 18.699063, lng: 73.919419 },*/
        { lat: 18.699375, lng: 73.919279},
        { lat: 18.699243, lng: 73.919315 }
    ]);
    const onEdit = useCallback((e) => {
        const newPath = e.getPath().getArray().map(latlng => ({
            lat: latlng.lat(),
            lng: latlng.lng()
        }));
        setPlantArea(newPath);
    }, []);

  return (
    <>
      {hasJWTToken() ? (
        <div className="Map">
            <h3 style={{color:"#000" }}>Real Time Robot Operation Tracking</h3>
            <p></p>
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <GoogleMap
                    mapContainerClassName="map-container"
                              zoom={19}
                              draggable={true}
                    center={{
                        /* lat: 18.698715, lng: 73.919295*/
                         lat: 18.699359, lng: 73.919299
                    }}
                          >
                              {plantArea.map((user, index) => (
                                <>
                                  <Polygon
                                      paths={[
                                              new window.google.maps.LatLng(user.plantAreaLat_1, user.plantAreaLon_1),
                                              new window.google.maps.LatLng(user.plantAreaLat_2, user.plantAreaLon_2),
                                              new window.google.maps.LatLng(user.plantAreaLat_3, user.plantAreaLon_3),
                                              new window.google.maps.LatLng(user.plantAreaLat_4, user.plantAreaLon_4),
                                      ]}
                                      editable={true}
                                      draggable={true}
                                      onMouseUp={onEdit}
                                     />
                                 </>
                              ))}
                              
                                      <Polygon
                                          paths={[
                                              new window.google.maps.LatLng(18.699407, 73.919260),
                                              new window.google.maps.LatLng(18.699407, 73.919320),
                                              new window.google.maps.LatLng(18.699007, 73.919308),
                                              new window.google.maps.LatLng(18.699007, 73.919228),
                                          ]}
                                          editable={true}
                                          draggable={true}
                                          
                                     />

                              {/*<Polygon*/}
                              {/*    paths={[*/}
                                      
                              {/*        new window.google.maps.LatLng(18.699359, 73.919299),*/}
                              {/*        new window.google.maps.LatLng(18.699348, 73.919317),*/}
                              {/*    ]}*/}
                              {/*    editable={true}*/}
                              {/*    draggable={true}*/}

                              {/*/>*/}
                                 
                             
                    {progressPath.map((user, index) => (
                        <>
                            <Polyline className="test" path={[
                                new window.google.maps.LatLng(user.source_lat, user.source_lon),
                                new window.google.maps.LatLng(user.destination_lat, user.destination_lon),
                            ]}
                                options=
                                {{
                                    strokeColor: "#99cc33", strokeWidth: "1px", strokeOpacity: 0,
                                    icons: [{
                                        icon: lineSymbol,
                                        offset: '0',
                                        repeat: '20px'
                                    }],
                                }}
                            />
                           
                            
                            <Marker
                                position={{ lat: user.source_lat, lng: user.source_lon }}
                                title={user.index}
                                label={"R" + user.robotID}
                            />
                            <Marker
                                position={{ lat: user.destination_lat, lng: user.destination_lon }}
                                title={user.index}
                                label={"R" + user.robotID}
                            />
                            {user.current_lat && user.current_lon ? (
                                <Polyline path={[
                                    new window.google.maps.LatLng(user.source_lat, user.source_lon),
                                    new window.google.maps.LatLng(user.current_lat, user.current_lon),

                                ]}
                                    key={user.index}
                                    options=
                                    {{
                                        strokeColor: "orange", strokeWidth: "2px", strokeOpacity: 0,
                                        icons: [{
                                            icon: lineSymbol,
                                            offset: '0',
                                            repeat: '20px'
                                        }],
                                    }}
                                />

                            ) : (
                                <></>
                            )
                            }
                            {user.current_lat && user.current_lon ? (
                                <Marker
                                    icon={imgicon}
                                    key={user.index}
                                    options={{ strokeColor: "#99cc33" }}
                                    position={{ lat: user.current_lat, lng: user.current_lon }}
                                    title={user.index}
                                />
                            ) : (
                                <Marker
                                    icon={imgicon}
                                    key={user.index}
                                    options={{ strokeColor: "#99cc33" }}
                                    position={{ lat: user.source_lat, lng: user.source_lon }}
                                    title={user.index}
                                />
                            )
                            }
                         
                        </>
                    ))}
                </GoogleMap>
            )}
            {progressPath.map((data, index) => (
              <div class="row" style={{ color: "#000" }}>
                    {data.straight_line_distance ? (
                        <div class="col-6">
                            <div class="col-6">Robot ID: {data.robotID}</div>
                            <div class="col-6">Distance (m): {data.straight_line_distance}</div>
                            <p></p><p></p>
                         </div>
                    ) : (
                            <div class="col-6"></div>
                        )
                    }
              </div>
                   
            ))}
        </div>
        ) :
        (
          <Route to={{ pathname: '#/login' }} />
        )
     }
   </>
    );
};
export default RobotLocation;