//*** Import react library
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios'
import mqtt from "mqtt/dist/mqtt";
import "../../Assets/Styles/robot.css";
import 'bootstrap/dist/css/bootstrap.css';
import Iframe from "react-iframe";
import bg from "../../Assets/Images/robot_background.jpg";
import robot_operation from "../../Assets/Images/robot_operation.jpeg";
import BatteryGauge from 'react-battery-gauge'
//import { MQTT } from "../RobotOperation/MQTT";
//import { Client_subscribe_to_mqtt_topic } from "../RobotOperation/MQTT";
//import { Create_broadcasting_command_message } from "../RobotOperation/MQTT";
//import { send_command_message_to_robot } from "../RobotOperation/MQTT";
//import { CaptureCamera } from "../RobotOperation/MQTT";
import "../../Assets/Styles/PowerLamp.css";
// MQTT code
///***Use anyone MQTT Broker
//const url = 'ws://broker.emqx.io:8083/mqtt'
//const url = 'ws://broker.mqttdashboard.com:8000/mqtt'
//const url = 'ws://broker.hivemq.com:8000/mqtt'
const url = 'wss://broker.hivemq.com:8884/mqtt'
//*** Global variables ***//
var PlantID = sessionStorage.getItem("user_plantid");
const sruserID = sessionStorage.getItem("user_id");
const MQTT_Subscribe_Topic = "zylv4u8fun1";
const MQTT_Receiver_Topic = "e1ypuwron21";
var SubscribtionStatus = false;
var Current_command_status = null;
var Current_motor_speed_status = null;
var Current_turn_status = null;
var Current_grass_cutter_status = null;
var Current_controller_battery_status = null;
var Current_motor_battery_status = null;
var Current_sprayer_status = null;
var IPAddress=null; //"http://192.168.0.1:8080/";
var RobotID;
///***
// * for Support url -https://www.emqx.com/en/blog/mqtt-js-tutorial
// * Browser
// * Using MQTT over WebSocket with ws and wss protocols
// * EMQX's ws connection default port is 8083, wss is 8084
// * Note that you need to add a path after the connection address, such as /mqtt
// */
//const url = 'ws://broker.emqx.io:8083/mqtt'
///***
// * Node.js
// * Using MQTT over TCP with mqtt and mqtts protocols
// * EMQX's mqtt connection default port is 1883, mqtts is 8883
// */
//const url = 'mqtt://broker.emqx.io:1883'
//**** Set options parameters of mqtt ***//
const options = {
    //*** clean session,
    clean: true,
    keepalive: 1060,
    connectTimeout: 500000,
    //*** Create Client Id
    clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`, //can be changed for something more specific if you need it
}
//*** Step-1 ***//
//*** Connect To MQTT broker ***//
var client1 = mqtt.connect(url, options)
client1 = new mqtt.connect(url, options)
client1.on('connect', function (err) {
    if (err) {
        console.log("Not Connected To MQTT Broker");
    }
    if (!err) {
        console.log("Connected To MQTT Broker");
    }
});
//*** Subscribe to MQTT topic
export const Client_subscribe_to_mqtt_topic = () => {
    var subscribe_topic;
    try {
        //*** Subescribe to Pblish Topic 'zylv4u8fun' ***//
        client1.subscribe(MQTT_Subscribe_Topic, function (err) {
            if (!err) {
                console.log('Client Subscribed to MQTT Pblish Topic')
                SubscribtionStatus = true;
            }
            if (err) {
                console.log('Client not Subscribed to MQTT Pblish Topic')
                SubscribtionStatus = false;
            }
        })
        subscribe_topic = MQTT_Subscribe_Topic;
        //*** Subscribe to message receiver topic ***//
        client1.subscribe(MQTT_Receiver_Topic, function (err1) {
            if (!err1) {
                console.log('Client Subscribed to MQTT receiver topic')
                SubscribtionStatus = true;
            }
            if (err1) {
                console.log('Client not Subscribed to MQTT receiver topic')
                client1.subscribe(MQTT_Receiver_Topic);
                SubscribtionStatus = false;
            }
        })
        subscribe_topic = subscribe_topic + "," + MQTT_Subscribe_Topic;
        return SubscribtionStatus;
    }
    catch (e) {
        SubscribtionStatus = false;
        console.log("Error to Subscribe Task: " + e.message + "");
    }
};
//*** Create frame for robots broadcast ***//
export const Create_broadcasting_command_message = (_robotID) => {
    //*** Send broadcast command to cloud database ***//
    const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + _robotID + "&rcs=Disconnected");
    var obj = new Object();
    obj.FID = _robotID; //RobotID;
    var Broadcasting_command_message = JSON.stringify(obj);
    Send_broadcasting_command_message_to_robot(Broadcasting_command_message, _robotID);
};

//*** Step-3 ***//
/***Send broadcasting frames to robots ***/
function Send_broadcasting_command_message_to_robot(Broadcasting_command_msg, robotID) {
    try {
        Client_subscribe_to_mqtt_topic();
        //client1.subscribe(MQTT_Subscribe_Topic, function (err) {
        //    if (!err) {
        //*** Send MQTT Broadcasting Message to Robot ***//
        client1.publish(MQTT_Subscribe_Topic, Broadcasting_command_msg)
        console.log("Step-1 (Broadcasting): Operator have sent Robot ID " + robotID + " to Robot and have completed step 1/6.");
        //alert("Step-1 (Broadcasting): Operator have sent Robot ID " + robotID + " to Robot and have completed step 1/6.");
        // }
        //})
    }
    catch (e) {
        console.log("Step-1 (Broadcasting) Error: " + e.message + "");
    }
}

//**** To check error ****//
client1.on('error', function (err) {
    //alert(err.message);
});
const stop_operation = () => {
  //*** Send frame to robots for stop operation ***//
    //** Send_frames_to_selected_robots("OFF")
    //** Unsubscribe topic ***//
    client1.unsubscribe("zylv4u8fun11", function (error) {
        if (error) {
            console.log(error)
        } else {
            console.log('Unsubscribed Toipic zylv4u8fun1')
            client1.end()
            Set_Variables_Status();
        }
    })
    client1.unsubscribe("e1ypuwron21", function (error) {
        if (error) {
            console.log(error)
        } else {
            console.log('Unsubscribed Toipic e1ypuwron21')
            client1.end()
            Set_Variables_Status();
        }
    })
    //*** End clients ***//
    client1.end()
    //*** Clear all global variables ***//
    Set_Variables_Status();
};
//*** Set global variables status null or false ***//
function Set_Variables_Status() {
    Current_command_status = null;
    Current_turn_status = null;
    Current_motor_speed_status = null;
    Current_grass_cutter_status = null;
    Current_sprayer_status = null;
}
//*** Send frames to robots ***//
export const send_command_message_to_robot = (commandmessage) => {
    try {
        client1.publish(MQTT_Subscribe_Topic, commandmessage)
        console.log(commandmessage)
    }
    catch (e) {
        console.log("Step-5 (Command Processing) Error: " + e.message + "");
    }
}
//*** Start code for multiple robot operation page ***//
export const RobotOperation = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [List, setList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [masterChecked, setMasterChecked] = useState(false);
    const [itemChecked, setItemChecked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cbStatus, setCBStatus] = useState(75);
    const [mbStatus, setMBStatus] = useState(35);
    const srPlantID = sessionStorage.getItem("user_plantid");
    const Light = ({ active, color }) =>
        <div class="Light" style={{ background: active ? color : "gray" }} onClick={() => fetchData()} />
    const PowerLight = ({ state }) =>
        <div class="PowerLight" onClick={() => fetchData()}>
            <Light active={state === 1} color="red" />
            <Light active={state === 0} color="orange" />
            <Light active={state === 2} color="lime" />
        </div>;
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = data;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setList(filteredData);
        }
        if (event.target.value == "") {
            setList(data);
        }
    };
    const filteredData = List.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    const handleRowSelected = (rows) => {
        setData(rows.selectedRows);
    };
    const contextActions = (
        <button onClick={() => alert(data.map((r) => r.robotID))}>
            Show Selected Rows
        </button>
    );
    
    //*** Select/Unselect table rows ***//
    const onMasterCheck = (e) => {
        let tempList = List;
        //*** Check/Uncheck all items
        tempList.map((user) => (user.selected = e.target.checked));
        //*** Update state
        setMasterChecked(e.target.checked);
        setList(tempList);
        setSelectedList(List.filter((e) => e.selected));
    }

    //*** Check table items ***//
    const onItemCheck = (e, item) => {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //*** Control master checkbox state
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        //*** Update state
        setMasterChecked(totalItems === totalCheckedItems);
        setList(tempList);
        setCheckedList(tempList)
        setSelectedList(List.filter((e) => e.selected));
        setItemChecked(true);
    }
    //*** Send broadcast frames to selected robots ***//
    const check_selected_robots_connected_status = () => {
        //** Subscribe to MQTT topic **//
        Client_subscribe_to_mqtt_topic();
            //*** Get selected list in array ***//
            setSelectedList(List.filter((e) => e.selected))
            let tempList = selectedList;
            tempList.map((user) => {
                //** Add and clear interval ***//
                const interval = setInterval(() => { }, 5000);
                clearInterval(interval);
                Create_broadcasting_command_message(user.robotID); 
            });
    }
    //** Received frames from robots to mqtt and from mqtt to UI **//
    client1.on('message', function (topic, message) {
        //*** message is Buffer ***//
        var obj = JSON.parse(message);
        if (topic === MQTT_Receiver_Topic) {
            //*** Add robot connected status (status from robots to Bhuhit UI) in cloud database ***//
            if (obj.FID != null && obj.IP != null) {
                IPAddress = obj.IP;
                RobotID = obj.FID;
                const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&rcs=Connected");
            }

            //*** Check received frames ***//
            if (obj.FID != null && obj.Cmd != null) {
                //alert(topic + "," + message);
                //*** Step-2 and Step-3 have executed. Operator have connected with Robots***//
                /*** Step-3 (Connectivity to Robot): You have connected to Robot and have completed step 3 /6. ***/
                console.log("Step-2 (Broadcasting): Operator have received Robot ID '" + obj.FID + "' from Robot and have completed step 2/6.");// message.payloading
                console.log("Step-3 (Connectivity to Robot): You are connected to Robot ID '" + obj.FID + "'  and have completed step 3 /6.");
                if (obj.Cmd != null) Current_command_status = obj.Cmd;
                if (obj.Spd != null) Current_motor_speed_status = obj.Spd;
                if (obj.Turn != null) Current_turn_status = obj.Turn;
                if (obj.GC != null) Current_grass_cutter_status = obj.GC;
                if (obj.SP != null) Current_sprayer_status = obj.SP;
                if (obj.CB != null) Current_controller_battery_status = obj.CB;
                if (obj.MB != null) Current_controller_battery_status = obj.MB;
              
                //**** Add command status (status from robots) to cloud database ****/
                if (obj.FID != null && obj.Cmd != null) {
                    let add_cmd_status = Axios("/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&lat=0&lon=0&cb="+obj.CB+"&mb="+obj.MB+"&cid=1")
                }

                if (obj.FID != null && obj.OPN != null) {
                    let add_oparation_status = Axios("/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=OFF&tun=D&spd=0&gc=0&sp=0&lat=0&lon=0&cb=0&mb=0&cid=1");
                }
            }
            //*** Update current lat and lon status from robots to cloud database ****/
            if (obj.FID != null && obj.lat != null && obj.lon != null) {
                let add_lat_lon_status = Axios("/PlantRobotsLocation?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&lat=" + obj.lat + "&lon=" + obj.lon + "&det=" + obj.Det + "");
            }
            //
            //if (obj.lat == null && obj.lon == null) {
               //  setSelectedList(List.filter((e) => e.selected))
            //fetchData();
           //user.selected = true;
                // get_selected_list_items();
            //}
        }
        
    });
    //*** Get previous seleted rows
    const get_selected_list_items = () => {
        let tempList = List;
        let tempSelectedList = selectedList;
        tempList.map((user1) => {
            tempSelectedList.map((user) => {
                if (user.selected) {
                    user.selected = true;
                }
               // alert(user.robotID)
                return user;
            });
            return user1;
        })
        setData(tempList);
        setList(tempList);
        setItemChecked(true);
        //tempList.map((obj) => {
        //    alert(obj.robotID);
            //tempSelectedList.map((obj1) => {
            //   // alert("ok2");
            //    alert(obj.robotID);
            //    ////alert(obj1.robotID);
            //    if (obj1.robotID === obj.robotID) {
            //        obj.selected = true;
            //        alert("ok3");
            //    }
            //    return obj1;
            //});
        //    return obj;
        //});
        //setList(tempList);
        //setData(tempList);
     }
    //*** Event to get selected rows(Optional)
    const show_camera = (command, turn) => {
        SubscribtionStatus = Client_subscribe_to_mqtt_topic()
        if (SubscribtionStatus === true) {
            setSelectedList(List.filter((e) => e.selected))
            let tempList = selectedList;
            tempList.map((user) => {
                const interval = setInterval(() => { }, 5000);
                clearInterval(interval);
            }
            );
        }
    }
    //*** Event to get selected rows(Optional)
    const send_command_to_selected_robots = (command, turn) => {
        //*** Add commands F ,B and S in session variable***// 
        if (command === "F" || command === "B" || command === "S") {
            sessionStorage.removeItem("Command");
            sessionStorage.setItem("Command", command);
        }
        //*** Create previous command and When command = "None" then command="S" ***//
        if (turn === "L" || turn === "R" || turn === "D") {
            if (command === "None" && sessionStorage.getItem("Command") === null) command = "S";
            if (command === "None" && (sessionStorage.getItem("Command") === "F" || sessionStorage.getItem("Command") == "B" || sessionStorage.getItem("Command") == "S")) command = sessionStorage.getItem("Command");
           // alert(command, turn + " from L and R "  );
        }
        //*** Subscribe to MQTT topic ***//
        SubscribtionStatus = Client_subscribe_to_mqtt_topic()
        //*** IF We subscribed to topic then we will send frame to robots ***//
        if (SubscribtionStatus === true) {
            //*** Get Selected List in array ***//
            setSelectedList(List.filter((e) => e.selected))
            let tempList = selectedList;
            tempList.map((user) => {
                //*** Set varibles to null
                Current_command_status = null;
                Current_motor_speed_status = null;
                Current_turn_status = null;
                Current_grass_cutter_status = null;
                Current_sprayer_status = null;
                //*** Add interval of 5 seconds to send frame for multiple robots and we will clear Interval ***//
                const interval = setInterval(() => { }, 5000);
                clearInterval(interval);
                let grassCutterOn = document.getElementById(user.robotID + "-GCOn");
                let grassCutterOff = document.getElementById(user.robotID + "-GCOff");
                let grassCutterOn1 = document.getElementById(user.robotID + "-GCOn1");
                let grassCutterOff1 = document.getElementById(user.robotID + "-GCOff1");
                if (grassCutterOn.checked || grassCutterOn1.checked) {
                    Current_grass_cutter_status = 1;
                }
                else if (grassCutterOff.checked || grassCutterOff1.checked) {
                    Current_grass_cutter_status = 0;
                }

                if (isModalOpen) {
                    if (grassCutterOn1.checked) {
                        grassCutterOn.checked = true;
                        grassCutterOn.value = 1;
                        Current_grass_cutter_status = 1;
                    }
                }
                if (!isModalOpen) {
                    if (grassCutterOn.checked) {
                        grassCutterOn1.checked = true;
                        grassCutterOn1.value = 1;
                        Current_grass_cutter_status = 1;
                    }
                }
                if (isModalOpen) {
                    if (grassCutterOff1.checked) {
                        grassCutterOff.value = 1;
                        grassCutterOff.checked = true;
                        Current_grass_cutter_status = 0;
                    }
                }
                if (!isModalOpen) {
                    if (grassCutterOff.checked) {
                        grassCutterOff1.value = 1;
                        grassCutterOff1.checked = true;
                        Current_grass_cutter_status = 0;
                    }
                }
                //if (grassCutterOn1.checked) {
                //    Current_grass_cutter_status = 1;
                //}
                //else if (grassCutterOff1.checked) {
                //    Current_grass_cutter_status = 0;
                //}
                let sprayerOn = document.getElementById(user.robotID + "-SPOn");
                let sprayerOff = document.getElementById(user.robotID + "-SPOff");
                let sprayerOn1 = document.getElementById(user.robotID + "-SPOn1");
                let sprayerOff1 = document.getElementById(user.robotID + "-SPOff1");

                if (isModalOpen) {
                    if (sprayerOn1.checked) {
                        sprayerOn.checked = true;
                        sprayerOn.value = 1;
                        Current_sprayer_status = 1;
                    }
                }
                if (!isModalOpen) {
                    if (sprayerOn.checked) {
                        sprayerOn1.checked = true;
                        sprayerOn1.value = 1;
                        Current_sprayer_status = 1;
                    }
                }
                if (isModalOpen) {
                    if (sprayerOff1.checked) {
                        sprayerOff.value = 1;
                        sprayerOff.checked = true;
                        Current_sprayer_status = 0;
                    }
                }
                if (!isModalOpen) {
                    if (sprayerOff.checked) {
                        sprayerOff1.value = 1;
                        sprayerOff1.checked = true;
                        Current_sprayer_status = 0;
                    }
                }
                
                //if (sprayerOn1.checked) {
                //    Current_sprayer_status = 1;
                //}
                //else if (sprayerOff1.checked) {
                //    Current_sprayer_status = 0;
                //}
                Current_command_status = command;
                Current_turn_status = turn;
                if (command === "S") { // when Command Stop then grass cutter and spraying status =0;
                    Current_grass_cutter_status=0;
                    Current_sprayer_status = 0;
                }
                if (Current_turn_status === null || Current_turn_status === '') Current_turn_status = 'D';
                if (Current_motor_speed_status === null || Current_motor_speed_status === '') Current_motor_speed_status = 255;
                if (Current_grass_cutter_status === null || Current_grass_cutter_status === '') Current_grass_cutter_status = 0;
                if (Current_sprayer_status === null || Current_sprayer_status === '') Current_sprayer_status = 0;
                //*** Send command to cloud database ***//
                const resResultAddCommandStatus = Axios("/PlantRobotOperation/addcmd?pid=" + user.plantID + "&uid=" + sruserID + "&rid=" + user.robotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");
                //*** Create Json object frame and send it to Robots ***//
                var obj = new Object();
                obj.FID = user.robotID;
                //*** IF command is OFF then we send frame to stop robot operation ***//
                if (command === "OFF") obj.OPN = command
                //*** IF command is F/B/S then we send frame to robot operation  ***//
               if (command !== "OFF") {
                    obj.Cmd = command;
                    obj.Turn = turn;
                    obj.Spd = 255;
                    obj.GC = Current_grass_cutter_status;
                    obj.SP = Current_sprayer_status;
                    obj.CB = 0;
                    obj.MB = 0;
                }
                var command_message = JSON.stringify(obj);
                try {
                    send_command_message_to_robot(command_message)
                    setItemChecked(true);
                    console.log(command_message)
                }
                catch (e) {
                 //   console.log("Step-5 (Command Processing) Error: " + e.message + "");
                }
            }
            );
        }
    };
   
    //*** Stop robots operation ***//
    const stop_selected_robots = () => {
        send_command_to_selected_robots("S", "D");
        //Temp comment on 20231018 RefreshCommandStatus();
    };

    const get_control_panel = (dvmodal) =>
    {
        setIsModalOpen(false);
        var dvimodal = document.getElementById(dvmodal);
        dvimodal.style.display = "none";
    };

    function Get_more(dvmodal, robot_id) {
        setIsModalOpen(true);
        var divmodal = document.getElementById(dvmodal);
        divmodal.style.display = "block";
        divmodal.scrollIntoView();
    };

    //*** Get single robot operation page ***//
    const get_single_robot_operation = (robotID, plantID) => {
        sessionStorage.setItem("robotID", robotID);
        window.location.href = "/#/PlantRobotOperation?rid=" + robotID + "&pid=" + plantID + "";
        //window.location.href = "http://bhuhit.com/#/PlantRobotOperation?rid=" + robotID + "&pid=" + plantID + "";
    };
    
    //*** React hook to refresh page if any changes, page will auto refresh ***//
    useEffect(() => {
       // const interval = setInterval(() => {
            fetchData();
       // }, 25000);
        //Clearing the interval
       // return () => clearInterval(interval);
    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])

    //const interval = setInterval(() => {
    //   fetchData();
    //}, 35000);
    //Clearing the interval
   // return () => clearInterval(interval);
    
    //*** Fetch data for multiple robot operation page ***//
    const fetchData = async () => {
        //const getrobotname = [];
        var plant_id = sessionStorage.getItem("user_plantid");
        let response = await Axios("/PlantRobotsOperation?pid=" + plant_id + "&rt=multiple");
        setData(response.data);
        setList(response.data);
        setItemChecked(false);
        var baseURL = "/#/RobotOperation?pid=" + plant_id + "";
        window.location.href = baseURL;
    }
    return (
      <>
          <div>
            <div>
                {/*<h3 style={{ marginTop: "1%", color: "#000" }}>Multiple Robot Operation</h3>*/}
            <div
                style={{
                    border: "1px solid #c0c0c0",
                    borderRadius: "5px",
                    width:"99%",
                    backgroundColor: "#fff",
                }}
                >
                     <div className="container" style={{ backgroundColor: "#fff" }}>
                        <div className="row">
                            <div className="col-sm-6"
                                    style={{
                                        height: "auto",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        float: "left",
                                        border: "none",
                                        borderRadius: "5px",
                                        fontSize: "130%",
                                        padding: "1%",
                                    }}
                                    >
                                   Multiple Robot Operations
                                </div>
                                <div className="col-sm-6">
                                    <p></p>
                                    <input
                                        type="text"
                                        placeholder="Search Results"
                                        style={{
                                            width: "40%",
                                            height: "30px",
                                            margin: "10px",
                                            float: "right",
                                            padding: "1.5%",
                                            borderRadius: "5px",
                                            borderBlockColor: "#c0c0c0",
                                            
                                        }}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                            </div>
                            </div>
                           
                            <div className="row">
                                <div className="col-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={masterChecked}
                                        id="mastercheck"
                                        onChange={(e) => onMasterCheck(e)}
                                    />
                                   &nbsp;All
                                </div>
                                
                                <div className="col-2 header-icon-size">
                                    <a onClick={() => check_selected_robots_connected_status()} ><i class="fa fa-cogs operation-icon-1">&nbsp;Connect</i></a>
                                </div>
                                <div className="col-2 header-icon-size">
                                    <a onClick={() => stop_selected_robots()}><i class="fa fa-circle-o-notch operation-icon-1">&nbsp;Stop Turn</i></a>
                                </div>
                                <div className="col-2 header-icon-size">
                                    <a onClick={() => send_command_to_selected_robots("OFF")}><i class="fa fa-power-off operation-icon-1">&nbsp;Stop</i></a>
                                </div>
                                <div className="col-3 header-icon-size">
                                    <a id="btnRefreshCommandStatus" onClick={() => fetchData()}> <i class="fa fa-refresh operation-icon-1">&nbsp;Refresh</i></a>
                                </div>
                            </div>
                            <div className="grid">
                                {List.map((user) => {
                                    return [
                                          <div key={user.robotID}  style={{ backgroundColor: "fff"}}>
                                            <div style={{ float: "right" }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={user.selected}
                                                            className="form-check-input"
                                                            id="rowcheck{user.id}"
                                                            onChange={(e) => onItemCheck(e, user)}
                                                        />
                                            </div>

                                            <div className="col container_multi_robots" style={{ position: 'relative', backgroundColor: "aliceblue", cursor: "pointer" }}>
                                                <div className="col camera_connection_status">
                                                    <a style={{ cursor: 'Pointer' }} id={user.robotID + IPAddress} onClick={() => show_camera("F", "D")} ><i class="fa fa-video-camera" style={{ color: 'yellow', fontSize: "20px", width: "100%"}}></i></a>
                                                </div>
                                                {user.controllerBatteryStatus ?
                                                    (
                                                        <div class="dvcb col" id={"dvCB" + user.robotID}>
                                                           CB&nbsp;<BatteryGauge class="controllerbattery" id={"CB" + user.robotID} value={user.controllerBatteryStatus} />
                                                        </div>
                                                    ) :
                                                    (
                                                        <div class="dvcb col" id={"dvCB1" + user.robotID}>
                                                            CB&nbsp;<BatteryGauge class="controllerbattery" id={"CB1" + user.robotID} value={100} />
                                                        </div>
                                                    )
                                                }
                                                {user.motorBatteryStatus ?
                                                    (
                                                        <div class="dvmb col" id={"dvMB" + user.robotID}>
                                                            MB&nbsp;<BatteryGauge className="motorbattery" id={"MB" + user.robotID} value={user.motorBatteryStatus} />
                                                        </div>
                                                    ) :
                                                    (
                                                        <div class="dvmb col" id={"dvMB1" + user.robotID}>
                                                            MB&nbsp;<BatteryGauge className="motorbattery" id={"MB1" + user.robotID} value={mbStatus} />
                                                        </div>
                                                    )
                                                }
                                                {user.broadcastStatus === 'Disconnected' ?
                                                    (
                                                        <div className="connection_status"><i class="fa fa-toggle-off" style={{ fontSize: '25px', color: 'red' }}></i>&nbsp;Disconnected</div>
                                                    ) :
                                                    (
                                                        <div className="connection_status"><i class="fa fa-toggle-on" style={{ fontSize: '25px', color: 'lime' }}></i>&nbsp;Connected</div>
                                                    )}
                                                {IPAddress && (RobotID === user.robotID) ? (
                                                    <Iframe id={"Iframe1" + user.robotID} key={"Iframe1" + user.robotID} url={IPAddress} className='responsive-iframe_1 inneriframe1' loading="lazy" allow="microphone;camera"></Iframe>
                                                )
                                                    : (
                                                        <Iframe id={"Iframe2" + user.robotID} key={"Iframe2" + user.robotID} url={bg} className='responsive-iframe_1 inneriframe1' loading="lazy" allow="microphone;camera"></Iframe>
                                                      )
                                                }
                                                &nbsp;
                                                <div style={{marginTop:"55%", backgroundColor:"aliceblue", marginLeft:"40%", fontSize:"120%"}}>
                                                    <div id={"dvPL" + user.robotID} class="PowerLight">
                                                      
                                                        {user.currentCommandStatus === "" || user.currentCommandStatus === null || user.currentCommandStatus === "New" ||
                                                            user.currentCommandStatus === "Stop" || user.currentCommandStatus === "S" ||
                                                            user.grassCutter === 'OFF' || user.sprayer === 'OFF' ?
                                                            (
                                                                <div id={"PL" + user.robotID} ><PowerLight state={0} /></div>
                                                            )
                                                            :
                                                            (
                                                                <></>
                                                            )
                                                        }

                                                        {user.grassCutter === 'ON' || user.sprayer === 'ON' ?
                                                            (
                                                                <div id={"PL" + user.robotID}><PowerLight state={1} /></div>
                                                            )
                                                            : (
                                                                <></>
                                                            )
                                                        }

                                                        {user.currentCommandStatus === "Moving Forward" || user.currentCommandStatus === "Moving Backward" ||
                                                            user.currentCommandStatus === "Forward Left" || user.currentCommandStatus === "Backward Left" ||
                                                            user.currentCommandStatus === "Forward Right" || user.currentCommandStatus === "Backward Right"
                                                            ?
                                                            (
                                                                <div id={"PL" + user.robotID}><PowerLight state={2} /></div>
                                                            )
                                                            : (
                                                                <></>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                   
                                                <div class="display_robot_name">
                                                        {user.robotName}
                                                        {/*<div>{IPAddress}</div>*/}
                                                </div>

                                                    <div key={user.robotID} id={"dv" + user.robotID} class="control_panel" style={{ display: "none", backgroundColor:"#ffffff"} }>
                                                        <div className="icon-forward-2">
                                                                <a id={"btnStart" + user.robotID} class="operation-icon" onTouchStart={() => send_command_to_selected_robots("F", "D")} onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up"></i></a>
                                                        </div>
                                                        <div className="icon-backward-2">
                                                                <a id={"btnReturn" + user.robotID} class="operation-icon" onTouchStart={() => send_command_to_selected_robots("B", "D")} onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down"></i></a>
                                                        </div>
                                                        <div className="icon-left">
                                                                <a id={"btnLeft" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left"></i></a>
                                                        </div>
                                                        <div className="icon-right">
                                                                <a id={"btnRight" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "R")} ><i class="fa fa-caret-square-o-right"></i></a>
                                                        </div>
                                                        <div className="icon-turn_stop-2">
                                                                <a id={"btnTurnStop" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o"></i></a>
                                                        </div>

                                                        <div className="icon-forward-right">
                                                                <a id="btnForwardRight" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "R")}><i class="fa fa-share"></i></a>
                                                        </div>

                                                        <div className="icon-forward-left">
                                                                <a id="btnForwardLeft" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "L")}><i class="fa fa-reply"></i></a>
                                                        </div>

                                                        <div className="icon-backward-right-2">
                                                                <a id="btnBackwardRight" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "R")}><i class="fa fa-reply fa-rotate-180"></i></a>
                                                        </div>

                                                        <div className="icon-backward-left-2">
                                                                <a id="btnBackwardLeft" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "L")}><i class="fa fa-share fa-rotate-180"></i></a>
                                                        </div>

                                                    </div>
                                                  
                                                    <div className="icon-connect_1 icon-size" >
                                                        <a id={"btnConnect" + user.robotID} class="operation-icon1" onClick={() => Create_broadcasting_command_message(user.robotID)}><i class="fa fa-cogs icon-size"></i>&nbsp;Connect</a>
                                                    </div>
                                                    
                                                    <div id={"dvGetMore" + user.robotID}  className="icon-control_panel_1 icon-size" onClick={() => Get_more("dvmodal" + user.robotID, user.robotID)}>
                                                        <a id={"btnGetMore" + user.robotID} class="operation-icon1" onClick={() => Get_more("dvmodal" + user.robotID, user.robotID)}><i class="fa fa-ellipsis-h icon-size"></i>&nbsp;More</a>
                                                    </div>

                                                    <div className="icon-stop-1 icon-size">
                                                        <a id={"btnRobotStop" + user.robotID} class="operation-icon1" onClick={() => stop_selected_robots()}><i class="fa fa-circle-o-notch"></i>&nbsp;Stop</a>
                                                    </div>
                                                 
                                                </div>
                                               
                                            </div>
                                           
                                            <div className="row gc-sp-text" id={"dvgcsp" + user.robotID} style={{ display: "block" }}>
                                                {/*<div class="col-2"></div>*/}
                                                <div className="col-sm-6 col_gc_sp">
                                                    GC:&nbsp;
                                                    {
                                                        (itemChecked === false && (user.grassCutter === 'ON')) ? (
                                                            <input
                                                                type="radio"
                                                                id={user.robotID + "-GCOn"}
                                                                name={user.robotID + "-GC"}
                                                                value="1"
                                                                checked="true"
                                                            />
                                                        ) : (
                                                            <input
                                                                type="radio"
                                                                id={user.robotID + "-GCOn"}
                                                                name={user.robotID + "-GC"}
                                                                value="1"
                                                            />
                                                        )
                                                    }
                                                    <span>&nbsp;On&nbsp;&nbsp;</span>
                                                    {(itemChecked === false && (user.grassCutter === "OFF")) ? (
                                                        <input
                                                            type="radio"
                                                            id={user.robotID + '-GCOff'}
                                                            name={user.robotID + "-GC"}
                                                            value="0"
                                                            checked="true"
                                                        />
                                                    ) : (
                                                        <input
                                                            type="radio"
                                                            id={user.robotID + '-GCOff'}
                                                            name={user.robotID + "-GC"}
                                                            value="0"
                                                        />
                                                    )
                                                    }

                                                    <span>&nbsp;Off&nbsp;&nbsp;</span>
                                                </div>

                                                <div className="col-sm-6 col_gc_sp">
                                                    SP:&nbsp;
                                                    {(itemChecked === false && (user.sprayer === 'ON')) ? (
                                                        <input style={{
                                                            color: '#99cc33'
                                                        }}
                                                            type="radio"
                                                            id={user.robotID + "-SPOn"}
                                                            name={user.robotID + "-SP"}
                                                            value="1"
                                                            checked="true"
                                                            
                                                           />
                                                    ) : (
                                                        <input style={{
                                                            color: '#99cc33'
                                                        }}
                                                            type="radio"
                                                            id={user.robotID + "-SPOn"}
                                                            name={user.robotID + "-SP"}
                                                            value="1"
                                                        />
                                                    )
                                                    }
                                                    <span>&nbsp;On&nbsp;&nbsp;</span>
                                                    {(itemChecked === false && (user.sprayer === 'OFF')) ? (
                                                        <input
                                                            type="radio"
                                                            id={user.robotID + "-SPOff"}
                                                            name={user.robotID + "-SP"}
                                                            value="0"
                                                            checked="true"
                                                            
                                                        />
                                                    ) : (
                                                        <input
                                                            type="radio"
                                                            id={user.robotID + "-SPOff"}
                                                            name={user.robotID + "-SP"}
                                                            value="0"
                                                        />
                                                    )
                                                    }
                                                    <span>&nbsp;Off&nbsp;&nbsp;</span>
                                                </div>
                                            </div>
                                            {/*width: '47%',*/}
                                            {/*top: "25%",*/}
                                            {/*left: "25%",*/}
                                            <div id={"dvmodal" + user.robotID} class="modalcontainer">
                                              <Modal.Dialog size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                 centered
                                               >
                                              <Modal.Header closeButton onClick={() => get_control_panel("dvmodal" + user.robotID)}>
                                                 <Modal.Title>
                                                      Robot Operations
                                                 </Modal.Title>
                                               </Modal.Header>

                                               <Modal.Body>
                                                        <p>
                                                            <div className="row gc-sp-text" id={"dvgcsp1" + user.robotID} style={{ display: "block" }}>
                                                                {/*<div class="col-2"></div>*/}
                                                                <div className="col-sm-6 col_gc_sp">
                                                                    GC:&nbsp;
                                                                    {
                                                                        (itemChecked === false && (user.grassCutter === 'ON')) ? (
                                                                            <input
                                                                                type="radio"
                                                                                id={user.robotID + "-GCOn1"}
                                                                                name={user.robotID + "-GC1"}
                                                                                value="1"
                                                                                checked="true"
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                type="radio"
                                                                                id={user.robotID + "-GCOn1"}
                                                                                name={user.robotID + "-GC1"}
                                                                                value="1"
                                                                            />
                                                                        )
                                                                    }
                                                                    <span>&nbsp;On&nbsp;&nbsp;</span>
                                                                    {(itemChecked === false && (user.grassCutter === "OFF")) ? (
                                                                        <input
                                                                            type="radio"
                                                                            id={user.robotID + '-GCOff1'}
                                                                            name={user.robotID + "-GC1"}
                                                                            value="0"
                                                                            checked="true"
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            type="radio"
                                                                            id={user.robotID + '-GCOff1'}
                                                                            name={user.robotID + "-GC1"}
                                                                            value="0"
                                                                        />
                                                                    )
                                                                    }

                                                                    <span>&nbsp;Off&nbsp;&nbsp;</span>
                                                                </div>

                                                                <div className="col-sm-6 col_gc_sp">
                                                                    SP:&nbsp;
                                                                    {(itemChecked === false && (user.sprayer === 'ON')) ? (
                                                                        <input style={{
                                                                            color: '#99cc33'
                                                                        }}
                                                                            type="radio"
                                                                            id={user.robotID + "-SPOn1"}
                                                                            name={user.robotID + "-SP1"}
                                                                            value="1"
                                                                            checked="true"
                                                                        />
                                                                    ) : (
                                                                        <input style={{
                                                                            color: '#99cc33'
                                                                        }}
                                                                            type="radio"
                                                                            id={user.robotID + "-SPOn1"}
                                                                            name={user.robotID + "-SP1"}
                                                                            value="1"
                                                                        />
                                                                    )
                                                                    }
                                                                    <span>&nbsp;On&nbsp;&nbsp;</span>
                                                                    {(itemChecked === false && (user.sprayer === 'OFF')) ? (
                                                                        <input
                                                                            type="radio"
                                                                            id={user.robotID + "-SPOff1"}
                                                                            name={user.robotID + "-SP1"}
                                                                            value="0"
                                                                            checked="true"
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            type="radio"
                                                                            id={user.robotID + "-SPOff1"}
                                                                            name={user.robotID + "-SP1"}
                                                                            value="0"
                                                                        />
                                                                    )
                                                                    }
                                                                    <span>&nbsp;Off&nbsp;&nbsp;</span>
                                                                </div>
                                                            </div>
                                                            <div className="col container_multi_robots" style={{ position: 'relative', backgroundColor: "aliceblue" }}>
                                                                {/*<Iframe id={"Iframe3" + user.robotID} key={"Iframe1" + user.robotID} url="http://192.168.1.109:8080/" className='responsive-iframe_1 inneriframe' loading="lazy" allow="microphone;camera"></Iframe>*/}
                                                                {/*<Iframe key={"Iframe1" + user.robotID} url="http://192.168.0.110:8888/" className='responsive-iframe_1' loading="lazy" allow="microphone;camera" id={'inneriframe'}></Iframe>*/}
                                                                {IPAddress && (RobotID === user.robotID) ? (
                                                                    <Iframe id={"Iframe3" + user.robotID} key={"Iframe1" + user.robotID} url={IPAddress} className='responsive-iframe_1 inneriframe1' loading="lazy"  allow="microphone;camera"></Iframe>
                                                                )
                                                                : (  
                                                                        //<></>
                                                                          <Iframe id={"Iframe4" + user.robotID} key={"Iframe2" + user.robotID} url={robot_operation} className='responsive-iframe_1 inneriframe1' loading="lazy" allow="microphone;camera"></Iframe>
                                                                 )
                                                                }
                                                                &nbsp;
                                                               {/* <div>{Webcam1}</div>*/}
                                                                <div style={{ marginTop: "55%", backgroundColor: "#ffffff", marginLeft: "40%", fontSize: "120%" }}>

                                                                    <div class="display_robot_name">{user.robotName}</div>
                                                                    <div id={"dvPL" + user.robotID} class="PowerLight">

                                                                        {user.currentCommandStatus === "" || user.currentCommandStatus === null || user.currentCommandStatus === "New" ||
                                                                            user.currentCommandStatus === "Stop" || user.currentCommandStatus === "S" ||
                                                                            user.grassCutter === 'OFF' || user.sprayer === 'OFF' ?
                                                                            (
                                                                                <div id={"PL" + user.robotID} ><PowerLight state={0} /></div>
                                                                            )
                                                                            :
                                                                            (
                                                                                <></>
                                                                            )
                                                                        }

                                                                        {user.grassCutter === 'ON' || user.sprayer === 'ON' ?
                                                                            (
                                                                                <div id={"PL" + user.robotID}><PowerLight state={1} /></div>
                                                                            )
                                                                            : (
                                                                                <></>
                                                                            )
                                                                        }

                                                                        {user.currentCommandStatus === "Moving Forward" || user.currentCommandStatus === "Moving Backward" ||
                                                                            user.currentCommandStatus === "Forward Left" || user.currentCommandStatus === "Backward Left" ||
                                                                            user.currentCommandStatus === "Forward Right" || user.currentCommandStatus === "Backward Right"
                                                                            ?
                                                                            (
                                                                                <div id={"PL" + user.robotID}><PowerLight state={2} /></div>
                                                                            )
                                                                            : (
                                                                                <></>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    <div key={"keydv" + user.robotID} id={"dvid" + user.robotID} class="control_panel" style={{ display: "block", backgroundColor: "#ffffff" }}>

                                                                        <div className="icon-forward-2">
                                                                            <a id={"btnStart_1" + user.robotID} class="operation-icon" onTouchStart={() => send_command_to_selected_robots("F", "D")} onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up"></i> F</a>
                                                                        </div>

                                                                        <div className="icon-backward-2">
                                                                            <a id={"btnReturn_1" + user.robotID} class="operation-icon" onTouchStart={() => send_command_to_selected_robots("B", "D")} onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down"></i> B</a>
                                                                        </div>

                                                                        <div className="icon-left">
                                                                            <a id={"btnLeft_1" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left"></i> L</a>
                                                                        </div>

                                                                        <div className="icon-right">
                                                                            <a id={"btnRight_1" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "R")} >R&nbsp;<i class="fa fa-caret-square-o-right"></i></a>
                                                                        </div>

                                                                        <div className="icon-turn_stop-2">
                                                                            <a id={"btnTurnStop_1" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o"></i>&nbsp;S</a>
                                                                        </div>

                                                                        <div className="icon-forward-right">
                                                                            <a id="btnForwardRight_1" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "R")}>FR&nbsp;<i class="fa fa-share"></i></a>
                                                                        </div>

                                                                        <div className="icon-forward-left">
                                                                            <a id="btnForwardLeft_1" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "L")}><i class="fa fa-reply"></i> FL</a>
                                                                        </div>

                                                                        <div className="icon-backward-right-2">
                                                                            <a id="btnBackwardRight_1" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "R")}>BR&nbsp;<i class="fa fa-reply fa-rotate-180"></i></a>
                                                                        </div>

                                                                        <div className="icon-backward-left-2">
                                                                            <a id="btnBackwardLeft_1" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "L")}><i class="fa fa-share fa-rotate-180"></i> BL</a>
                                                                        </div>

                                                                    </div>

                                                                    <div className="icon-connect_1 modal-icon-size">
                                                                        <a id={"btnConnect_1" + user.robotID} class="modal_connect_stop_link" onClick={() => Create_broadcasting_command_message(user.robotID)} ><i class="fa fa-cogs"></i> Connect</a>
                                                                        {/*<a id={"btnConnect" + user.robotID} class="operation-icon" onClick={() => Create_broadcasting_command_message(user.robotID)} ><i class="fa fa-cogs operation-icon" id="connect-icon_1"></i></a>*/}
                                                                    </div>

                                                                    <div className="icon-stop-1 modal-icon-size">
                                                                        {/* <a id={"btnRobotStop" + user.robotID} class="operation-icon" onClick={() => stop_selected_robots()} ><i class="fa fa-circle-o-notch" style={{ color: 'yellow', fontSize: "35px", width: "100%" }}></i></a>*/}
                                                                        <a id={"btnRobotStop_1" + user.robotID} class="modal_connect_stop_link" onClick={() => stop_selected_robots()} ><i class="fa fa-circle-o-notch"></i> Stop</a>
                                                                    </div>

                                                                </div>

                                                            </div>
                                               </p>
                                               </Modal.Body>
                                                    {/*<Modal.Footer>*/}
                                                    {/*    <Button variant="secondary" onClick={() => get_control_panel("dvid" + user.robotID, "dvgcsp1" + user.robotID, "dvblank" + user.robotID, "dvmodal" + user.robotID)}>*/}
                                                    {/*        Close*/}
                                                    {/*    </Button>*/}
                                                    {/*</Modal.Footer>*/}
                                               </Modal.Dialog>
                                            </div> 
                                        </div>
                                    ];
                                    }
                                )
                                }
                            </div>

                            <p></p>
                            <p></p>
                            <div className="row">
                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnStart" onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up operation-icon" id="forward-icon"></i>Forward</a>
                                </div>
                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnReturn" onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down operation-icon" id="backward-icon"></i>Backward</a>
                                </div>

                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnLeft" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left operation-icon" id="left-icon"></i>Left</a>
                                </div>

                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnTurnStop" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o operation-icon" id="turn-icon"></i>Stop Turn</a>
                                </div>

                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnRight" onClick={() => send_command_to_selected_robots("None", "R")} ><i class="fa fa-caret-square-o-right operation-icon" id="right-icon"></i>Right</a>
                                </div>
                                
                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => stop_selected_robots()}><i class="fa fa-circle-o-notch operation-icon" id="stop-icon"></i>Stop Robot</a>
                                </div>

                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => send_command_to_selected_robots("OFF")}><i class="fa fa-power-off operation-icon" id="off-icon"></i>Stop</a>
                                </div>

                             </div>
                    </div>
              
                </div>
            </div>
           
          </div>
      </>
    );
};

               
