import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../../Assets/Styles/navbar.css';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu"; 
import { IconContext } from "react-icons/lib";
/*import logo from "../../Assets/Images/energy_guru_logo.jpg";*/
import logo from "../../Assets/Images/energy_guru_logo_new.png";
import Axios from "axios";
//*** Set Axios Default BaseURl
//Axios.defaults.baseURL = "https://localhost:7248";
Axios.defaults.baseURL = "https://bhuhit.com/api";
const Nav = styled.div`
 /* background:#EFFD90*//*#f0e891*//*#f7e98e*//*#f9f8e1*//*#fffde9*//*#fcf8be*//*#f2f27a*//*#15171c*/;
  background:#fff; /*linear-gradient(to left, #FFFB7D, #85FFBD)*/
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position:relative;
`;
const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const SidebarNav = styled.nav`
 /* background: linear-gradient(to left,green, black);*/
  background:#99cc33;/*darkslategrey*//*#00d4ff*//*#00573F*//*#008B8B*//*#0D98BA*//*#cdf214*//*#20B2AA*//*#808000*//*#01411C*//*#004953*//*#01796F*//*#444C38*//*#008080*//*#006241*//*#18453B*//*#317873*//*#4A5D23*//*#008B8B*//*#4B5320*//*Olive*//*#006C4F*//*#00AED6*/;
  width: 250px;
  height:100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  color:#000;
 `;
const SidebarWrap = styled.div`
  width: 100%;
`;
const Sidebar = () => {
    const [sidebar, setSidebar] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isLoggedIn, setisLoggedIn] = useState(false);
    const [isCloudStatus, setCloudStatus] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const srusername = sessionStorage.getItem("username");
    const srPlantID = sessionStorage.getItem("user_plantid");
    const srtoken=sessionStorage.getItem("token");
    const [username, setUsername] = useState("");
    const [token, settoken] = useState("");
    const [count, setCount] = useState(0);
    const renderusername = () => { setUsername(srusername) };
    //** To Check internet connection is on or off **//
    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
          //  setUsername(srusername);
        };
        // Listen to the online status
        window.addEventListener('online', handleStatusChange);
         //Listen to the offline status
        window.addEventListener('offline', handleStatusChange);
     
        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

   //** End to check innternet connectivity is on or off **//

   // setInterval(checkCloudConnectivity, 10000);
    useEffect(() => {
        // If internet connection is on then will check cloud database connectivity
      if (isOnline) {
        //Implementing the setInterval method
        const interval = setInterval(() => {
            // setCount(count + 1);
            const response = Axios("/CloudConnectivityStatus");
            //const response = Axios("http://bhuhit.com/api/CloudConnectivityStatus");
           // alert(response.data);
            response.then((res) => {
                if (res.data == 'Success') setCloudStatus(true); 
                else if (res.data == 'unSuccess') setCloudStatus(false); 
            }) 
            response.catch(
                function (error) {
                    setCloudStatus(false); 
                    console.log('Error Message:' + error)
                    return Promise.reject(error)
                }
            )
            }, 5000);
        //Clearing the interval
        return () => clearInterval(interval);
      }

    }, [isCloudStatus]);

   //Updated code on 20240202,sidebar will refresh when user have login.
    useEffect(() => {
        const interval = setInterval(() => {
            if (isLoggedIn === false) {
                setisLoggedIn(true)
                //srtoken = sessionStorage.getItem("token");
                //settoken(srtoken)
                //alert(token);
                //if (srtoken) {
                //    alert(srtoken);
                //    srusername = sessionStorage.getItem("username");
                //}
            }
            else if (isLoggedIn === true) {
                setisLoggedIn(false)
            }
        }, 5000);
        //Clearing the interval
        return () => clearInterval(interval);
    }, [isLoggedIn]);

    //** Logout user **//
    const logout = () => {
       // alert("logoutccc");
        LogoutUser();
        showSidebar();
        sessionStorage.clear();
        //localStorage.clear();
        window.location.href = "#/Login";
       // window.location.href = 'http://localhost:44463/#/Login';
        //window.location.href = 'https://bhuhit.com/#/Login';
    }
    //** Logout user **//
    const LogoutUser = () => {
        var user_id = sessionStorage.getItem("user_id");
        var plant_id = sessionStorage.getItem("user_plantid");
        //alert(user_id);
        if (user_id != null) {
            const response = Axios("#/Logout?uid="+ user_id +"&pid=" + plant_id +"");
            //const response = Axios("https://bhuhit.com/api/Logout?uid=" + user_id + "&pid=" + plant_id + "");
            response.then((res) => {
                if (res.data === 'Success') setCloudStatus(true);
                else if (res.data === 'unSuccess') setCloudStatus(false);
            })
            response.catch(
                function (error) {
                    setCloudStatus(false);
                    console.log('Error Message:' + error)
                    return Promise.reject(error)
                }
            )
        }
    }
    const PlantRobotOperation = () => {
       // window.location.href = "http://localhost:44463/#/RobotsOperation?pid=" + srPlantID + "";
        window.location.href = "/#/RobotsOperation?pid="+srPlantID+"";
         //window.location.href = "https://bhuhit.com/#/RobotsOperation?pid=" + srPlantID + "";
    }
        return (
            <>
                <IconContext.Provider value={{color: "#e0e0e0" }}>
                    <Nav>
                        <img className="logo_2" src={logo}/>
                        <NavIcon to="#">
                            <FaIcons.FaBars onClick={showSidebar} />
                        </NavIcon>
                        <h1
                            style={{
                                textAlign: "center",
                                marginLeft: "6%",
                                color: "#004953"
                            }}
                        >
                            <div class="container-fluid p-2 text-center">
                               {/* <h2>Robotics Operation & Monitoring System</h2>*/}
                                <h2>Bhuhit</h2>
                            </div>
                        </h1>
                        
                        {/*<div className="user">*/}
                        {/*    <Dropdown class="user">*/}
                        {/*        <Dropdown.Toggle variant="">*/}
                        {/*            <i class="fa fa-bell" style={{ color: '#99cc33' }}></i>*/}
                        {/*        </Dropdown.Toggle>*/}
                        {/*        {isLoggedIn === false || isLoggedIn === true ? (*/}
                        {/*            <Dropdown.Menu>*/}
                        {/*                <Dropdown.Item href="#/CurrentCommandStatus">*/}
                        {/*                    <i class="fa fa-reorder" style={{ color: '#99cc33' }}></i> Robots Current Command Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/PlantRobotsOpnStatus">*/}
                        {/*                    <i class="fa fa-gears" style={{ color: '#99cc33' }}></i> Robots Operation Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/CompletedCommandsStatus">*/}
                        {/*                    <i class="fa fa-gears" style={{ color: '#99cc33' }}></i> Completed Commands Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/PendingCommandsStatus">*/}
                        {/*                    <i class="fa fa-gears" style={{ color: '#99cc33' }}></i> Pending Commands Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/CurrentBatteryStatus">*/}
                        {/*                    <i class="fa fa-battery" style={{ color: '#99cc33' }}></i> Robots Current Battery Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/RobotsBroadcastStatus">*/}
                        {/*                    <i class="fa fa-signal" style={{ color: '#99cc33' }}></i> Current Robot Connected Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/RobotLocation">*/}
                        {/*                    <i class="fa fa-map-marker" style={{ color: '#99cc33' }}></i> Robot location*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#">*/}
                        {/*                    <i class="fa fa-cloud" style={{ color: '#99cc33' }}></i> Current Weather Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#">*/}
                        {/*                    <i class="fa fa-user" style={{ color: '#99cc33' }}></i> User Logged Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#">*/}
                        {/*                    <i class="fa fa-user" style={{ color: '#99cc33' }}></i> Customer User Logged Status*/}
                        {/*                </Dropdown.Item>*/}
                        {/*            </Dropdown.Menu>*/}
                        {/*        ) : (<></>)*/}
                        {/*        }*/}
                        {/*    </Dropdown>*/}
                        {/*</div>*/}
                        {/*<div className="user column">*/}
                        {/*    <Dropdown class="user">*/}
                        {/*        <Dropdown.Toggle variant="">*/}
                        {/*            <i class="fa fa-tasks" style={{ color:'#99cc33'}}></i>*/}
                        {/*        </Dropdown.Toggle>*/}
                        {/*        {isLoggedIn === false || isLoggedIn === true ? (*/}
                        {/*            <Dropdown.Menu>*/}
                        {/*                <Dropdown.Item href="#/RobotsOperation?pid=1">*/}
                        {/*                    <i class="fa fa-gears" style={{ color: '#99cc33' }}></i><a style={{ cursor: "Pointer" }} onClick={PlantRobotOperation}> Plant Robots Operation</a> */}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/CurrentPlantLevelGrassCutting">*/}
                        {/*                    <i class="fa fa-sitemap" style={{ color:'#99cc33' }}></i> Current Plant Level Grass Cutting*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/CurrentPlantLevelSpraying">*/}
                        {/*                    <i class="fa fa-shower" style={{ color: '#99cc33' }}></i> Current Plant Level Spraying*/}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/CurrentRobotsLevelGrassCutting">*/}
                        {/*                    <i class="fa fa-sitemap" style={{ color: '#99cc33' }}></i> Current Robot Level Grass Cutting */}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#/CurrentRobotsLevelSpraying">*/}
                        {/*                    <i class="fa fa-shower" style={{ color: '#99cc33' }}></i> Current Robot Level Spraying */}
                        {/*                </Dropdown.Item>*/}
                        {/*                <Dropdown.Item href="#">*/}
                        {/*                    <i class="fa fa-server" style={{ color: '#99cc33' }}></i> Robots Operation Sheduling*/}
                        {/*                </Dropdown.Item>*/}
                        {/*            </Dropdown.Menu>*/}
                        {/*        ) : (<></>)*/}
                        {/*        }*/}
                        {/*    </Dropdown>*/}
                        {/*</div>*/}
                        <div className="user column">
                            {
                                isCloudStatus && isOnline ? (
                                    <i class="fa fa-cloud-upload" style={{ color: '#99cc33' }}></i>
                                ) :
                                    (
                                        <i class="fa fa-cloud" style={{ color: '#f2971f' }}></i>
                                    )
                            }
                        </div>
                        <div className="user column">
                            {
                                isOnline ? (
                                    <i class="fa fa-rss" style={{ color:'#99cc33' }}></i>
                                ) :
                                    (

                                        <i class="fa fa-rss" style={{ color:'#f2971f' }} ></i>
                                    )
                            }
                        </div>
                        <div className="user column">
                            <Dropdown class="user">
                                <Dropdown.Toggle variant="">
                                    <a onClick={renderusername}></a>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {isLoggedIn === false || isLoggedIn === true ? (
                                        <Dropdown.Item href="#">
                                            <i class="fa fa-email" style={{ color:'#99cc33' }}></i> {srusername}
                                        </Dropdown.Item>
                                    ) :
                                    (
                                        <Dropdown.Item href="#">
                                            Hi, Guest
                                        </Dropdown.Item>
                                    ) 
                                   }
                                    {srtoken ? (
                                        <Dropdown.Item>
                                            <i class="fa fa-lock" style={{ color: '#99cc33' }}></i> <a style={{cursor:"Pointer"}} onClick={logout}>Logout</a>
                                        </Dropdown.Item>
                                    ) :
                                    (
                                        <Dropdown.Item href="#/Login">
                                            <i class="fa fa-user" style={{ color: '#99cc33' }}></i> Login
                                        </Dropdown.Item>
                                    )
                                  }
                                    <Dropdown.Item href="#/Dashboard">
                                            <i class="fa fa-dashboard" style={{ color: '#99cc33' }}></i> Dashboard
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Nav>
                    {srtoken ? (
                        <SidebarNav sidebar={sidebar} style={{ color:'black' }}>
                            <SidebarWrap>
                                <NavIcon to="#">
                                    <AiIcons.AiOutlineClose onClick={showSidebar} />
                                </NavIcon>
                                {SidebarData.map((item, index) => {
                                    return <SubMenu item={item} key={index} />;
                                })}
                            </SidebarWrap>
                        </SidebarNav>
                    ) : (<></>)
                    }
                </IconContext.Provider>
            </>
        );
    };
  
export default Sidebar;
