//*** Import React Library
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Axios from 'axios'
import "../../Assets/Styles/robot.css";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";
import "../../Assets/Styles/Form.css";
import { Plant}  from "../Account/Plant";
import * as FaIcons from "react-icons/fa";
import { openTab } from "../Account/Plant";
import { FetchPlantId } from "../Account/EditPlant";

//*** Create columns for react table
const columns = [
    /*{ name: "ID", selector: (row) => row.plant_id, sortable: true, },*/
    { name: "Edit", selector: (row) => <a className="alink" onClick={() => edit_plant(row.plant_id)}>{row.plant_id}</a>, sortable: true,},
    { name: "Company", selector: (row) => row.company_name, sortable: true, },
    { name: "Plant", selector: (row) => row.plant_name, sortable: true },
    { name: "Contact Person", selector: (row) => row.contact_person_name, sortable: true },
    { name: "Contact Email", selector: (row) => row.contact_email_id, sortable: true },
    { name: "Contact Number", selector: (row) => row.contact_number, sortable: true },
    { name: "Address", selector: (row) => row.plant_address, sortable: true },
    { name: "Lat", selector: (row) => row.plant_lat, sortable: true },
    { name: "Lon", selector: (row) => row.plant_lon, sortable: true },
    { name: "TZD", selector: (row) => row.plant_time_zone_diff, sortable: true },
    { name: "LBL", selector: (row) => row.low_battery_level, sortable: true },
    { name: "Date", selector: (row) => row.plant_added_date, sortable: true },
 ];

const edit_plant = (plantID) => {
   // alert(plantID);
    sessionStorage.removeItem("editpid");
    sessionStorage.setItem("editpid", plantID);
    window.location.href = "#/EditPlant?pid=" + plantID + "";
   // openTab('EditPlant', 'btnEditPlant', '#fff', 'Update', plantID );
   // sessionStorage.setItem("editpid", plantID);
    //FetchPlantId(plantID)
    //openTab1('EditPlant', 'btnEditPlant', '#fff', plantID)
    //alert("dffffff");
    //window.location.href = "#/EditPlant?pid=" + plantID + "";
};

//*** Create custom style for react table
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};

export const SearchPlant = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [plantData, setPlantData] = useState([]);
    const [plantList, setPlantList] = useState([]);
    
    //*** Seach Textbox text change event
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = plantData;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setPlantList(filteredData);
        }
        if (event.target.value === "") {
            setPlantList(plantData);
        }
    };
    //*** Filter table
    const filteredData = plantList.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        fetchPlantData();
        //setFormData();
    }, [])
    useEffect(() => {
        console.log(plantData)
    }, [plantData])

    //*** Fetch data from API // To Search 94fbr
    const fetchPlantData = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        var user_id = sessionStorage.getItem("user_id");
        var _token = sessionStorage.getItem("token");
        if (_token) _token = "Token exists";
        let response = await Axios("/Plant?pid=" + plant_id + "&uid=" + user_id + "&pn=SearchAllPlant");
        setPlantData(response.data)
        setPlantList(response.data)
    }
    
    return (
        <div className="page-container">
         <div className="search-container">
                <h2 className="form-title"><FaIcons.FaSearch /> Search Plant</h2>
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-12"><a className="btndelete" href="#/AddPlant">Add New Plant</a></div>
                </div>
             <div>
                <div
                    style={{
                        border: "1px solid #c0c0c0",
                        borderRadius: "5px",
                        margin:"2%",
                        backgroundColor: "#fff",
                    }}
                >
                    <div className="container dvscroll" >
                        <div className="row dvscroll">
                            <div className="col-sm-12">
                                <input
                                    type="text"
                                    placeholder="Search Results"
                                    style={{
                                        width: "20%",
                                        height: "30px",
                                        margin: "10px",
                                        float: "right",
                                        border: "none",
                                        padding: "1.5%",
                                        borderRadius: "5px",
                                    }}
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <p></p>
                                <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                    search={true}
                                    columns={columns}
                                    data={filteredData}
                                    highlightOnHover={true}
                                    customStyles={customStyles}
                                >
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default SearchPlant;