//AddController.js
import React, { useState, useEffect } from 'react';
import "../../Assets/Styles/Form.css";
import Axios from 'axios';
import * as FaIcons from "react-icons/fa";
import { hasJWTToken } from "../Account/RouteGuard";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
export const PlantController = () => {
    const _userID = sessionStorage.getItem("user_id");
    const [plantOption, setPlantOption] = useState([]);
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [formData, setFormData] = useState({
        plant_id: null,
        controller_id: null,
        controller_name: '',
        user_id: _userID,
        jwt_token: _token,
        controller_mode: 'Insert',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        var newErrors = validateForm(formData);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            formData.controller_mode = "Insert";
            formData.user_id = 1;
            let objControllerData = Axios.post("/PlantControllers", formData)
            //'Controller submitted successfully!'
           // formData.plant_id = null;
            formData.controller_name = '';
            setFormData(formData);
            formData.message = "Added new controller successfully!";
            console.log('Form submitted successfully!');
        } else {
            formData.message = 'Controller submission failed due to validation errors.'
            console.log('Form submission failed due to validation errors.');
        }
    };
    const validateForm = (data) => {
        const errors = {};
        if (!data.plant_id.trim()) {
            errors.plant_id = 'Plant ID is required';
        } else if (data.plant_id.length > 10) {
            errors.plant_id = 'Plant ID should not be less than or more than 10 digits';
        }
        if (!data.controller_name.trim()) {
            errors.controller_name = 'Robot name is required';
        } else if (data.controller_name.length > 50) {
            errors.controller_name = 'Robot name should not be less than or more than 50 characters';
        }
        return errors;
    };
    const handleSelectChange = (e) => {
        formData.plant_id = e.target.value;
    };
    useEffect(() => {
        fetchPlantList();
    }, [])
    useEffect(() => {
        console.log(plantOption)
    }, [plantOption])
    const fetchPlantList = async () => {
        var userID = sessionStorage.getItem("user_id");
        let response = await Axios("/DropDownList/GetPlantList?paramid=1&=uid=" + userID + "&pn=PlantList&dn=PlantList");
        setPlantOption(response.data);
    };
    return (
        <div className="container-ul">
            <div className="form-container">
            <h2 className="form-title"><FaIcons.FaRegEdit /> Add New Controller</h2>
            <span className="form-message">
                {formData.message}
            </span>
            <form onSubmit={handleSubmit}>
                <div>
                    <Form.Select name="plant_id" onChange={handleSelectChange}>
                        {plantOption.map(opt => (
                            <option value={opt.plant_id}>{opt.plant_name}</option>
                        ))}
                    </Form.Select>
                </div>
                {/*<div>*/}
                {/*    <input*/}
                {/*        className="form-input"*/}
                {/*        type="number"*/}
                {/*        maxLength="10"*/}
                {/*        name="plant_id"*/}
                {/*        value={formData.plant_id}*/}
                {/*        onChange={handleChange}*/}
                {/*        placeholder="Enter plant ID (Max limit 10 digits)"*/}
                {/*    />*/}
                {/*    {errors.plant_id && (*/}
                {/*        <span className="error-message">*/}
                {/*            {errors.plant_id}*/}
                {/*        </span>*/}
                {/*    )}*/}
                {/*</div>*/}
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="controller_name"
                        maxLength="50"
                        value={formData.controller_name}
                        onChange={handleChange}
                        placeholder="Enter controller name (Max limit 50 chars)"
                    />
                    {errors.controller_name && (
                        <span className="error-message">
                            {errors.controller_name}
                        </span>
                    )}
                </div>
                <div class="row">
                    <div class="col-8"><button className="submit-button" type="submit">Submit</button></div>
                    <div class="col-4"><a className="btndelete" href="#/SearchController">Search Controller</a></div>
                </div>
            </form>
            </div>
        </div>
    );
}
export default PlantController;