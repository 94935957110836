// Plant.js
import React, { useState, useEffect } from 'react';
import "../../Assets/Styles/Form.css";
import Axios from 'axios';
import * as FaIcons from "react-icons/fa";
import { hasJWTToken } from "../Account/RouteGuard";
export const AddPlant = () => {
    const _userID = sessionStorage.getItem("user_id");
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [responseData, setResponseData] = useState();
    let [formData, setFormData] = useState({
        company_id: '',
        plant_name: '',
        contact_person_name: '',
        contact_number: '',
        contact_email_id: '',
        plant_address: '',
        plant_lat: '',
        plant_lon: '',
        plant_time_zone_diff: '',
        low_battery_level: '',
        plant_added_date:'',
        company_name: '',
        plant_id:'0',
        user_id: _userID,
        jwt_token: _token,
        plant_mode: "Insert",
        message:'',
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        var newErrors = validateForm(formData);
         setErrors(newErrors);
        //alert(formData.companyID);
       // let response = Axios.post("/Plant", formData)
        //fetch('https://localhost:7248/Plant', {
        //    method: 'POST',
        //    headers: { 'Content-type': 'application/json', 'Accept': 'application/json' },
        //    body: formData
        //}).then(r => r.json()).then(res => {
        //    if (res) {
        //        this.setState({ message: 'New Employee is Created Successfully'});
        //    }
        //});
        //alert("gh1");
      //  let plant_response = Axios.post("/Plant", formData)
        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            //alert("k");
            let objPlant = Axios.post("/Plant", formData)
            //setFormData(formData);
            formData.company_id = '';
            formData.plant_name= '';
            formData.contact_person_name= '';
            formData.contact_number= '';
            formData.contact_email_id= '';
            formData.plant_address= '';
            formData.plant_lat= '';
            formData.plant_lon= '';
            formData.plant_time_zone_diff= '';
            formData.low_battery_level= '';
            formData.plant_added_date='';
            formData.company_name= '';
            formData.plant_id='0';
            formData.user_id= _userID;
            formData.jwt_token= _token;
            formData.plant_mode= "Insert";
            formData.message = "Added new plant successfully!";
            console.log('Form submitted successfully!');
        } else {
            formData.message = 'Plant submission failed due to validation errors.'
            console.log('Form submission failed due to validation errors.');
        }
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.company_id.trim()) {
            errors.company_id = 'Company ID is required';
        } else if (data.company_id.length > 10) {
            errors.company_id = 'Company ID should not be less than or more than 10 digits';
        }

        if (!data.plant_name.trim()) {
            errors.plant_name = 'Plant name is required';
        } else if (data.plant_name.length > 50) {
            errors.plant_name = 'Plant name should not be less than or more than 50 characters';
        }

        if (!data.contact_person_name.trim()) {
            errors.contact_person_name = 'Contact person is required';
        } else if (data.contact_person_name.length > 50) {
            errors.contact_person_name = 'Contact Person should not be less than or more than 50 characters';
        }

        if (!data.contact_number.trim()) {
            errors.contact_number = 'Contact number is required';
        } else if (data.contact_number.length > 35) {
            errors.contact_number = 'Contact number should not be less than or more than 35 characters';
        }

        if (!data.contact_email_id.trim()) {
            errors.contact_email_id = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.contact_email_id)) {
            errors.contact_email_id = 'Email is invalid';
        }

        if (!data.plant_address.trim()) {
            errors.plant_address = 'Plant address is required';
        } else if (data.plant_address.length > 100) {
            errors.plant_address = 'Plant address should not be less than or more than 100 characters';
        }

        if (!data.plant_lat.trim()) {
            errors.plant_lat = 'Plant latitude is required';
        } else if (data.plant_lat.length > 15) {
            errors.plant_lat = 'Plant lat should not be less than or more than 15 characters';
        }

        if (!data.plant_lon.trim()) {
            errors.plant_lon = 'Plant longitude is required';
        } else if (data.plant_lon.length > 15) {
            errors.plant_lon = 'Plant lon should not be less than or more than 15 characters';
        }

        if (!data.plant_time_zone_diff.trim()) {
            errors.plant_time_zone_diff = 'Plant time zone difference is required';
        } else if (data.plant_time_zone_diff.length > 5) {
            errors.plant_time_zone_diff = 'Time zone difference should not be less than or more than 5 digits';
        }

        if (!data.low_battery_level.trim()) {
            errors.low_battery_level = 'Plant low battery level is required';
        } else if (data.low_battery_level.length > 3) {
            errors.low_battery_level = 'Low battery level should not be less than or more than 3 digits';
        }
        return errors;
    };
    return (
        <div className="container-ul" style={{ marginTop:"7%"} }>
            <div className="form-container">
            <h2 className="form-title"><FaIcons.FaRegEdit/> Add New Plant</h2>
            <span className="form-message">
                {formData.message}
            </span>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        className="form-input"
                        type="number"
                        maxLength="10"
                        name="company_id"
                        value={formData.company_id}
                        onChange={handleChange}
                        placeholder="Enter company ID (Max limit 10 digits)"
                    />
                    {errors.company_id && (
                        <span className="error-message">
                        {errors.company_id}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="plant_name"
                        maxLength="50"
                        value={formData.plant_name}
                        onChange={handleChange}
                        placeholder="Enter plant name (Max limit 50 char)"
                    />
                    {errors.plant_name && (
                        <span className="error-message">
                            {errors.plant_name}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="contact_person_name"
                        value={formData.contact_person_name}
                        maxLength="50"
                        onChange={handleChange}
                        placeholder="Enter contact person (Max limit 50 char)"
                    />
                    {errors.contact_person_name && (
                        <span className="error-message">
                            {errors.contact_person_name}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="contact_number"
                        value={formData.contact_number}
                        onChange={handleChange}
                        maxLength="35"
                        placeholder="Enter contact number (Max limit 35 char)"
                    />
                {errors.contact_number && (
                    <span className="error-message">
                        {errors.contact_number}
                    </span>
                )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="contact_email_id"
                        value={formData.contact_email_id}
                        onChange={handleChange}
                        maxLength="30"
                        placeholder="Enter contact email ID (Max limit 30 char)"
                    />
                    {errors.contact_email_id && (
                        <span className="error-message">
                        {errors.contact_email_id}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="plant_address"
                        value={formData.plant_address}
                        onChange={handleChange}
                        maxLength="100"
                        placeholder="Enter plant address (Max limit 100 char)"
                    />
                    {errors.plant_address && (
                        <span className="error-message">
                            {errors.plant_address}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="plant_lat"
                        value={formData.plant_lat}
                        maxLength="15"
                        onChange={handleChange}
                        placeholder="Enter plant latitude (Max limit 15 char)"
                    />
                    {errors.plant_lat && (
                        <span className="error-message">
                            {errors.plant_lat}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="plant_lon"
                        value={formData.plant_lon}
                        maxLength="15"
                        onChange={handleChange}
                        placeholder="Enter plant longitude (Max limit 15 char)"
                    />
                    {errors.plant_lon && (
                        <span className="error-message">
                        {errors.plant_lon}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="number"
                        name="plant_time_zone_diff"
                        maxLength="5"
                        value={formData.plant_time_zone_diff}
                        onChange={handleChange}
                        placeholder="Enter plant time zone difference (Max limit 5 digits)"
                    />
                    {errors.plant_time_zone_diff && (
                        <span className="error-message">
                            {errors.plant_time_zone_diff}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="number"
                        name="low_battery_level"
                        value={formData.low_battery_level}
                        maxLength="3"
                        onChange={handleChange}
                        placeholder="Enter low_battery_level (Max limit 3 digits)"
                    />
                    {errors.low_battery_level && (
                        <span className="error-message">
                            {errors.low_battery_level}
                        </span>
                    )}
                </div>
                    <div class="row">
                        <div class="col-9"><button className="submit-button" type="submit">Submit</button></div>
                        <div class="col-3"><a className="btndelete" href="#/SearchPlant">Search Plant</a></div>
                    </div>
            </form>
            </div>
        </div>
    );
}
export default AddPlant;