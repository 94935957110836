//*** AddRobot.js
import React, { useState, useEffect } from 'react';
import "../../Assets/Styles/Form.css";
import Axios from 'axios';
import * as FaIcons from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import { hasJWTToken } from "../Account/RouteGuard";
import Select from 'react-select';
export const PlantRobot= () => {
    const _userID = sessionStorage.getItem("user_id");
    const [plantOption, setPlantOption] = useState([]);
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [responseData, setResponseData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    let [formData, setFormData] = useState({
        plant_id:null,
        robot_id: null,
        robot_name: '',
        robot_code: '',
        wheel_perimeter:'',
        mqtt_sending_topic: '',
        mqtt_receiving_topic:'',
        low_battery_level:'',
        user_id: _userID,
        jwt_token: _token,
        robot_mode: 'Insert',
        message:'',
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    //handleChange = (selectedOption) => {
    //    setSelectedOption({ selectedOption }, () =>
    //        console.log(`Option selected:`, selectedOption)
    //    );
    //};
    const handleSubmit = (e) => {
        e.preventDefault();
        var newErrors = validateForm(formData);
            setErrors(newErrors);
        //alert(formData.companyID);
        //let response = Axios.post("/farm", formData)
        //fetch('https://localhost:7248/farm', {
        //    method: 'POST',
        //    headers: { 'Content-type': 'application/json', 'Accept': 'application/json' },
        //    body: formData
        //}).then(r => r.json()).then(res => {
        //    if (res) {
        //        this.setState({ message: 'New Employee is Created Successfully'});
        //    }
        //});
        //alert("gh1");
        //  let farm_response = Axios.post("/farm", formData)
        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            formData.robot_mode = "Insert";
            formData.user_id = 1;
            let objRobotData = Axios.post("/PlantRobot", formData)
            //setResponseData(objRobotData.data);
            //let tempData = responseData;
            //tempData.map((user) => {
            //    if (user.message) {
            //        //*** Invalid User Name
            //        //setErrorMessages({ name: "invalid", message: "Invalid user name." });
            //        // return;
            //        alert(user.message);
            //        formData.message = user.message;
            //    }
            //}
            //)
            //clearFormData();
            //formData.plant_id = null;
            formData.robot_id = null;
            formData.robot_name = '';
            formData.robot_code = '';
            formData.wheel_perimeter = '';
            formData.mqtt_sending_topic = '';
            formData.mqtt_receiving_topic = '';
            formData.low_battery_level = '';
            formData.user_id = 1;
            formData.jwt_token = _token;
            formData.robot_mode = 'Insert';
            //formData.message = '';
             //'farm submitted successfully!'
            formData.message = "Added new robot successfully!";
            console.log('Form submitted successfully!');
        } else {
            formData.message = 'farm submission failed due to validation errors.'
            console.log('Form submission failed due to validation errors.');
        }
    };
    const validateForm = (data) => {
        const errors = {};
        if (!data.plant_id.trim()) {
            errors.plant_id = 'Plant ID is required';
        } else if (data.plant_id.length > 10) {
            errors.plant_id = 'Plant ID should not be less than or more than 10 digits';
        }

        if (!data.robot_name.trim()) {
            errors.robot_name = 'Robot name is required';
        } else if (data.robot_name.length > 50) {
            errors.robot_name = 'Robot name should not be less than or more than 50 characters';
        }

        if (!data.robot_code.trim()) {
            errors.robot_code = 'Robot Code is required';
        } else if (data.robot_code.length > 50) {
            errors.robot_code = 'Robot Code should not be less than or more than 50 characters';
        }

        if (!data.wheel_perimeter.trim()) {
            errors.wheel_perimeter = 'Wheel perimeter is required';
        } else if (data.wheel_perimeter.length > 35) {
            errors.wheel_perimeter = 'Wheel perimeter should not be less than or more than 35 characters';
        }

        if (!data.mqtt_sending_topic.trim()) {
            errors.mqtt_sending_topic = 'Sending topic is required';
        } else if (data.mqtt_sending_topic.length > 100) {
            errors.mqtt_sending_topic = 'Sending topic should not be less than or more than 100 characters';
        }

        if (!data.mqtt_receiving_topic) {
            errors.mqtt_receiving_topic = 'Receving topic is required';
        } else if (data.mqtt_receiving_topic.length > 10) {
            errors.mqtt_receiving_topic = 'Receving topic should not be less than or more than 150 characters';
        }

        if (!data.low_battery_level.trim()) {
            errors.low_battery_level = 'Low battery level is required';
        } else if (data.low_battery_level.length > 15) {
            errors.low_battery_level = 'Low battery level should not be less than or more than 15 characters';
        }
        return errors;
    };
    //useEffect(() => {
    //    fetchPlantList();
    //}, [])
    //useEffect(() => {
    //    console.log(plantOption)
    //}, [plantOption])
    //const fetchPlantList = async () => {
    //    //const getrobotname = [];
    //    var plant_id = sessionStorage.getItem("user_plantid");
    //    let response = await Axios("/Robot/GetPlantList?paramid=1&=uid=1&pn=PlantRobot&dn=PlantList");
    //    setPlantOption(response.data);
    //   // var baseURL = "/#/PlantRobot?";
    //   // window.location.href = baseURL;
    //}
    const handleSelectChange = (e) => {
        formData.plant_id = e.target.value;
    };
    useEffect(() => {
        fetchPlantList();
    }, [])
    useEffect(() => {
        console.log(plantOption)
    }, [plantOption])
    const fetchPlantList = async () => {
        var userID = sessionStorage.getItem("user_id");
        let response = await Axios("/DropDownList/GetPlantList?paramid=1&=uid=" + userID + "&pn=PlantList&dn=PlantList");
        setPlantOption(response.data);
    };
    return (
        <div className="container-ul">
            <div className="form-container">
            <h2 className="form-title"><FaIcons.FaRegEdit/> Add New Robot</h2>
            <span className="form-message">
                {formData.message}
            </span>
            <form onSubmit={handleSubmit}>
                <div>
                    <Form.Select name="plant_id" onChange={handleSelectChange}>
                        {plantOption.map(opt => (
                            <option value={opt.plant_id}>{opt.plant_name}</option>
                        ))}
                    </Form.Select>
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="robot_name"
                        maxLength="50"
                        value={formData.robot_name}
                        onChange={handleChange}
                        placeholder="Enter robot name (Max limit 50 chars)"
                    />
                    {errors.robot_name && (
                        <span className="error-message">
                            {errors.robot_name}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="robot_code"
                        value={formData.robot_code}
                        maxLength="20"
                        onChange={handleChange}
                        placeholder="Enter robot code (Max limit 20 chars)"
                    />
                    {errors.robot_code && (
                        <span className="error-message">
                            {errors.robot_code}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="number"
                        name="wheel_perimeter"
                        value={formData.wheel_perimeter}
                        onChange={handleChange}
                        maxLength="10"
                        placeholder="Enter wheel perimeter (Max limit 10 digits)"
                    />
                {errors.wheel_perimeter && (
                    <span className="error-message">
                        {errors.wheel_perimeter}
                    </span>
                )}
                </div>
         
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="mqtt_sending_topic"
                        value={formData.mqtt_sending_topic}
                        onChange={handleChange}
                        maxLength="20"
                        placeholder="Enter sending topic (Max limit 20 chars)"
                    />
                    {errors.mqtt_sending_topic && (
                        <span clas sName="error-message">
                            {errors.mqtt_sending_topic}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="mqtt_receiving_topic"
                        value={formData.mqtt_receiving_topic}
                        onChange={handleChange}
                        maxLength="20"
                        placeholder="Enter receiving topic (Max limit 20 chars)"
                    />
                    {errors.mqtt_receiving_topic && (
                        <span className="error-message">
                            {errors.mqtt_receiving_topic}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="low_battery_level"
                        value={formData.low_battery_level}
                        maxLength="5"
                        onChange={handleChange}
                        placeholder="Enter low_battery_level (Max limit 5 chars)"
                    />
                    {errors.low_battery_level && (
                        <span className="error-message">
                            {errors.low_battery_level}
                        </span>
                    )}
                </div>
                <div class="row">
                    <div class="col-9"><button className="submit-button" type="submit">Submit</button></div>
                    <div class="col-3"><a className="btndelete" href="#/SearchRobot">Search Robot</a></div>
                </div>
            </form>
            </div>
        </div>
    );
}
export default PlantRobot;